const worksData = [
    {
        title: "Desafío ESPN",
        subTitle: "League of Legends",
        portada: require("assets/works/Desafío ESPN/Desafío ESPN.webp"),
        images: [
            require("assets/works/Desafío ESPN/IMG_2645.jpg"),
            require("assets/works/Desafío ESPN/IMG_2849.jpg"),
            require("assets/works/Desafío ESPN/IMG_3002.jpg"),
            require("assets/works/Desafío ESPN/IMG_E2641.jpg"),
            require("assets/works/Desafío ESPN/desafio 1.jpg"),
            require("assets/works/Desafío ESPN/fotos fh 7.jpg"),
        ],
        videos: []
    },
    {
        title: "Mapping",
        subTitle: "Cerro San Javier",
        portada: require("assets/works/Mapping/Mapping.png"),
        images: [require("assets/works/Mapping/Mapping.png")],
        videos: []
    },
    {
        title: "Ruinas de Tavarengue",
        subTitle: "Paraguay",
        portada: require("assets/works/Ruinas de Tavarengue/Ruinas de Tavarengue.webp"),
        images: [
            require("assets/works/Ruinas de Tavarengue/IMG_6741.jpg"),
            require("assets/works/Ruinas de Tavarengue/IMG_6769.jpg"),
            require("assets/works/Ruinas de Tavarengue/IMG_6804.jpg"),
            require("assets/works/Ruinas de Tavarengue/IMG_6806.jpg"),
            require("assets/works/Ruinas de Tavarengue/IMG_8792.jpg"),
            require("assets/works/Ruinas de Tavarengue/IMG_8961.jpg"),
        ],
        videos: []
    },
    {
        title: "Auditorio “La Ballena Azul”",
        subTitle: "Centro Cultural Kirchner CCK",
        portada: require("assets/works/Auditorio La Ballena Azul/Auditorio La Ballena Azul.webp"),
        images: [
            require("assets/works/Auditorio La Ballena Azul/Rectangle (3).webp"),
            require("assets/works/Auditorio La Ballena Azul/Rectangle (2).webp"),
            require("assets/works/Auditorio La Ballena Azul/Auditorio La Ballena Azul.webp"),
        ],
        videos: []
    },
    {
        title: "Sitio de la Memoria",
        subTitle: "Ex Esma",
        portada: require("assets/works/Sitio de la Memoria/Sitio de la Memoria.jpg"),
        images: [
            require("assets/works/Sitio de la Memoria/IMG_5165.jpg"),
            require("assets/works/Sitio de la Memoria/IMG_5212.jpg"),
            require("assets/works/Sitio de la Memoria/Sitio de la Memoria.jpg"),
        ],
        videos: []
    },
    {
        title: "Planeta Agua",
        subTitle: "Tecnópolis",
        portada: require("assets/works/Planeta Agua/Planeta Agua.jpeg"),
        images: [
            require("assets/works/Planeta Agua/WhatsApp Image 2023-10-03 at 11.19.58 AM.jpeg"),
            require("assets/works/Planeta Agua/WhatsApp Image 2023-10-03 at 11.20.06 AM.jpeg"),
            require("assets/works/Planeta Agua/WhatsApp Image 2023-10-03 at 11.20.17 AM.jpeg"),
            require("assets/works/Planeta Agua/planeta agua1.webp"),
            require("assets/works/Planeta Agua/Planeta Agua.jpeg"),
        ],
        videos: []

    },
    {
        title: "Experiencia Vialidad",
        subTitle: "Tecnópolis",
        portada: require("assets/works/Experiencia Vialidad/Experiencia Vialidad.webp"),
        images: [
            require("assets/works/Experiencia Vialidad/Fotos Web Tecnop 2015-96.jpg"),
            require("assets/works/Experiencia Vialidad/CONA9942.JPG"),
            require("assets/works/Experiencia Vialidad/Fotos Web Tecnop 2015-97.jpg"),
            require("assets/works/Experiencia Vialidad/Experiencia Vialidad.webp")
        ],
        videos: []
    },
    {
        title: "La Nación",
        subTitle: "",
        portada: require("assets/works/La Nación/La Nación.webp"),
        images: [
            require("assets/works/La Nación/IMG_2895.jpg"),
            require("assets/works/La Nación/la nacion.jpg"),
            require("assets/works/La Nación/La Nación.webp"),
        ],
        videos: []
    },
    {
        title: "Tributo a Gustavo Ceratti",
        subTitle: "",
        portada: require("assets/works/Tributo a Gustavo Ceratti/Tributo a Gustavo Ceratti.webp"),
        images: [
            require("assets/works/Tributo a Gustavo Ceratti/DSCN8459.JPG"),
            require("assets/works/Tributo a Gustavo Ceratti/IMG_3403.JPG"),
            require("assets/works/Tributo a Gustavo Ceratti/Tributo a Gustavo Ceratti.webp"),
        ],
        videos: []
    },
    {
        title: "Celi",
        subTitle: "",
        portada: require("assets/works/CELI/CELI.webp"),
        images: [
            require("assets/works/CELI/P1290535.webp"),
            require("assets/works/CELI/P1290585.webp"),
            require("assets/works/CELI/P1290617 2.webp"),
            require("assets/works/CELI/P1290699.webp"),
            require("assets/works/CELI/CELI.webp"),
        ],
        videos: []
    },
    {
        title: "Mapping",
        subTitle: "3 Naciones",
        portada: require("assets/works/3 Naciones/3 Naciones.webp"),
        images: [
            require("assets/works/3 Naciones/IMG_2556.webp"),
            require("assets/works/3 Naciones/3 Naciones.webp"),
        ],
        videos: []
    },
    {
        title: "Museo",
        subTitle: "Casa del Poeta Antonio Esteban Agüero",
        portada: require("assets/works/Museo Aguero/portada.jpeg"),
        images: [
            require("assets/works/Museo Aguero/muA1.jpeg"),
            require("assets/works/Museo Aguero/muA2.jpeg"),
            require("assets/works/Museo Aguero/muA3.jpeg"),
            require("assets/works/Museo Aguero/muA4.jpeg"),
        ],
        videos: []
    }
];

export default worksData;